













































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apikefuLists,
    apikefuDel,
    apikefuStatus,
    apikefuLogin
} from '@/api/application/service'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class SelffetchShop extends Vue {
    // 表单数据
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/

    // 获取列表数据
    getLists(page?: number): void {
        page && (this.pager.page = page)
        // 请求管理员列表
        this.pager.request({
            callback: apikefuLists
        })
    }

    // 删除
    handleDelete(id: number) {
        apikefuDel({ id }).then(() => {
            // 删除成功就请求新列表
            this.getLists()
        })
    }

    // 更改状态
    changeStatus(value: 0 | 1, id: number) {
        apikefuStatus({
            id,
            disable: value
        }).then(() => {
            this.getLists()
        })
    }
    // 进入工作台
    enterKefu(id: number) {
        apikefuLogin({ id }).then(res => {
            window.open(res.url)
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getLists()
    }

    /** E Life Cycle **/
}
